import React from 'react'
import { SmallBar, StyledAboutus } from './style'

const AboutUs = () => {
  return (
    <StyledAboutus id='about'>
        <h1>About Us</h1>
        <SmallBar/>
        <p>Lorem ipsum dolor sit amet consectetur. Scelerisque etiam tincidunt risus lobortis non cursus. Mauris imperdiet dictum urna euismod tincidunt molestie congue. Nulla risus urna volutpat interdum pretium nulla vitae placerat est. Donec ut pretium in.    
        </p><br/>
        <p>Lorem ipsum dolor sit amet consectetur. Scelerisque etiam tincidunt risus lobortis non cursus. Mauris imperdiet dictum urna euismod tincidunt molestie congue. Nulla risus urna volutpat interdum pretium nulla vitae placerat est. Donec ut pretium in.    
        </p>
        </StyledAboutus>
  )
}

export default AboutUs