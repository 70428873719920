import { Col } from 'antd'
import React from 'react'
import image1 from '../../Assets/Images/Home/home3-banner1.jpg.png'
import image2 from '../../Assets/Images/Home/home3-banner2.jpg.png'
import image3 from '../../Assets/Images/Home/home3-banner3.jpg.png'
import image4 from '../../Assets/Images/Home/home3-banner4.jpg.png'
import { StyledButton, StyledHome, StyledHomePage, StyledImage } from './style'
import { Row } from '../../Components/Row'

export const HomePage = () => {

    return (
        <StyledHome id='home'>
            {/* <a href='#shop'>
            <img src={home_image} alt='home' style={{width:'100%'}}/>
            </a> */}
            <Row gutter={[24, 24]}>
                <Col span={24} md={8}>
                    <StyledHomePage>
                        <h1>Hello world! <br />
                            Welcome to <br />
                            Simson Garments
                        </h1><br />
                        <div style={{ width: '70px', padding: '2px', backgroundColor: '#F43434', margin: 'auto', marginTop: '20px' }}></div><br />
                        <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam nonummy nibh euismod tincidunt ut
                            laoreet dolore magna aliquam erat volutpat. Ut wisi
                            enim ad minim veniam, quis nostrud exerci tation
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea
                            commodo consequat
                        </p>
                        <a href='#shop'>
                            <StyledButton>ALL COLLECTIONS</StyledButton>
                        </a>

                    </StyledHomePage>
                </Col>
                <Col span={24} md={16}>
                    <Row >
                        <Col span={24} md={12}>
                            <StyledImage >
                                <img src={image1} alt="" />
                            </StyledImage>
                        </Col>

                        <Col span={24} md={12}>
                            <StyledImage>
                                <img src={image2} alt=""  />
                            </StyledImage>
                        </Col>

                        <Col span={24} md={12}>
                            <StyledImage>
                                <img src={image3} alt=""  />
                            </StyledImage>
                        </Col>

                        <Col span={24} md={12}>
                            <StyledImage>
                                <img src={image4} alt="" />
                            </StyledImage>
                        </Col>

                    </Row>
                </Col>
            </Row>
            <br />
            <br />

        </StyledHome>
    )
}
